import ModelBase from "./shared/model-base.js";

const OPTIONS = {
  columns: ["id", "category", "name", "price"],
};

export default class Product extends ModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes);
  }
}
